<template>
  <v-container>
    Send images to your QOwnNotes desktop application.
  </v-container>
</template>

<script>
export default {
  data: () => ({
  })
}
</script>
